@import "../../variables.scss";

.fixed-top {
  .navbar.active {
    .navbar-collapse {
      height: calc(100vh - 60px);
    }
  }
}

.fixed__mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  .fixed-top {
    top: 38px;
  }
  .navbar.active {
    .navbar-collapse {
      height: calc(100vh - 98px);
    }
  }
}
.header {
  &__placeholder {
    width: 100%;
    height: 61px;
    @include respond(md) {
      height: 71px;
    }
  }
  &__top {
    font-size: 12px;
    padding: 10px 0;
    text-align: center;
    background: map-get($colors, "black");
  }
}

.navbar {
  background: map-get($colors, "grey");
  padding: 10px 0 3px;
  border-bottom: 1px solid map-get($colors, "yellow");
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
  @include respond(md) {
    padding: 15px 0 8px;
  }

  &.active {
    position: relative;
    border-bottom: none;

    &.fadein {
      .navbar-collapse {
        opacity: 0;
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 200%;
      border-bottom: 1px solid map-get($colors, "yellow");
      margin-left: -50%;
      top: 61px;
    }
    .navbar-collapse {
      display: flex;
      justify-content: space-between;
      opacity: 1;
      height: calc(100vh - 98px);
    }
    .nav__button {
      width: 100%;
      margin-bottom: 60px;
      @include respond(md) {
        margin-bottom: 0;
      }
    }
    .navbar-toggler-icon {
      height: 0;
      opacity: 0.8;
      &:before {
        transform: rotate(45deg);
        transform-origin: top left;
        width: 115%;
        top: -10px;
      }
      &:after {
        transform: rotate(-45deg);
        transform-origin: bottom left;
        width: 115%;
        top: 10px;
      }
    }
  }
  .navbar-collapse {
    padding: 20px 0;
    transition: all 800ms ease;
    opacity: 0;
    @include respond(md) {
      padding: 0;
      opacity: 1;
    }
  }

  .container {
    padding: 0 15px;
  }

  &-nav {
    a {
      color: map-get($colors, "white");
      margin: 25px 0;

      position: relative;
      font-size: 18px;
      text-transform: uppercase;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      @include respond(md) {
        padding: 0 20px;
        margin: 0 20px;
        width: 100%;
        font-size: 14px;
        width: 74px;
      }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: auto;
      }

      &:before {
        content: "";
        display: block;
        bottom: -10px;
        position: absolute;
        height: 2px;
        width: 100%;
        background: map-get($colors, "orange");
        transform: scaleX(0);
        transform-origin: right center;
        transition: transform 0.25s ease-in-out;
        left: 50%;
        margin-left: -25px;
        width: 50px;
        @include respond(md) {
          bottom: -21px;
          width: 100%;
          left: 0;
          margin-left: 0;
        }
      }
      &:hover {
        text-decoration: none;
        &:before {
          transform-origin: left center;
          transform: scaleX(1);
        }
      }
      &:first-child {
        padding-top: 10px;
        @include respond(md) {
          padding-top: 0;
        }
      }
    }
  }

  .navbar-toggler {
    border: none !important;
    padding: 0;
    margin-right: 10px;
    display: block;
    margin-top: -10px;
    cursor: pointer;
    @include respond(md) {
      display: none;
    }
    &-icon {
      background-image: none;
      background-color: map-get($colors, "white");
      height: 2px;
      width: 25px;
      position: relative;
      opacity: 0.6;
      transition: opacity 1000ms ease;
      &:before,
      &:after {
        position: absolute;
        content: "";
        display: block;
        height: 2px;
        width: 25px;
        background-color: map-get($colors, "white");
        top: -8.5px;
        transition: all 250ms ease-in-out;
      }
      &:after {
        top: 8.5px;
      }
    }
  }
  .nav__button {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid map-get($colors, "white");
    border-radius: 2px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 8px 16px;
    z-index: 1;
    white-space: nowrap;
    margin-top: -3px;
    color: map-get($colors, "white");
    transition: color 0.2s ease-in, background-color 0.2s ease-in;

    &:hover {
      background-color: map-get($colors, "white");
      color: map-get($colors, "black");
      text-decoration: none;
    }
  }
}

.logo {
  color: map-get($colors, "white") !important;
  font-size: 30px;
  line-height: 16px;
  font-weight: 600;
  width: 20%;
  margin: 0;
  span {
    color: map-get($colors, "yellow");
  }
  &__mcrc {
    font-size: 30px;
  }
  &__mutations {
    font-size: 15px;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
}

.nav-active {
  &:hover {
    text-decoration: none !important;
  }
  &:before {
    transform: scaleX(1) !important;
    background: map-get($colors, "white") !important;
  }
}

.navbar-nav {
  width: 100%;
  justify-content: space-around;
  align-items: center;
  @include respond(lg) {
    margin-right: 50px;
  }
}

.show,
.collapse {
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
  }
}
