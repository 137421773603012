@import "../../variables.scss";

.footer {
  margin-top: 50px;

  &__bottom {
    text-align: center;
    padding: 15px 0;
    background: map-get($colors, "grey");
    font-size: 14px;

    @include respond(sm) {
      text-align: left;
    }
  }
  .logo {
    padding-top: 25px;
    padding-bottom: 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    a {
      color: map-get($colors, "white");
      &:hover {
        text-decoration: none;
      }
    }
  }
  .referrence {
    padding-bottom: 15px;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;

    &__container {
      padding-bottom: 15px;

      p {
        font-size: 14px;
        line-height: 18px;
      }
    }
    &.active {
      span {
        background: map-get($colors, "yellow");
        transform: rotate(90deg);
      }
    }

    span {
      background: map-get($colors, "white");
      border-radius: 50%;
      width: 21px;
      height: 21px;
      margin-right: 10px;
      margin-bottom: -5px;
      display: inline-block;
      position: relative;
      text-align: center;
      line-height: 18px;
      transform: rotate(0);
      transition: 300ms ease-in-out;

      &:before {
        content: "";
        position: absolute;
        top: 6.5px;
        left: 6px;
        border: solid map-get($colors, "black");
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
  .pf-details {
    font-size: 12px;
    color: #fff;
    text-align: center;
    width: 150px;

    @include respond(md) {
      max-width: 121px;
    }

    img,
    .code {
      color: #fff;
      display: inline-block;
    }

    .code {
      margin: 10px 0 0;
      width: 100%;
    }
  }
}

.footer__links {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @include respond(md) {
    width: auto;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.footer__navigation {
  display: flex;
  justify-content: center;

  @include respond(sm) {
    justify-content: flex-end;
  }

  a {
    width: 100%;
    display: block;
    margin: 10px 0;
    color: map-get($colors, "white");

    @include respond(md) {
      width: auto;
      display: inline-block;
      margin: 0 0 0 30px;

      &:not(:last-child) {
        &:after {
          content: "|";
          margin-left: 30px;
        }
      }
    }
  }

  p {
    line-height: 23px;
    font-size: 14px;
  }
}
