$colors: (
	"black": #000,
	"white": #fff,
	"grey": #1f2532,
	"lightgrey": #444,
	"darkgrey": #1e2532,
	"yellow": #cfd329,
	"orange": #f47e64
);

$grid-breakpoints: (
	"xs": 576px,
	"sm": 768px,
	"md": 992px,
	"lg": 1200px,
	"xl": 1440px,
	"xxl": 1920px
);

@mixin respond($size) {
	@if (
		$size ==
			xxl or
			$size ==
			xl or
			$size ==
			lg or
			$size ==
			md or
			$size ==
			sm or
			$size ==
			xs
	) {
		@media screen and (min-width: map-get($grid-breakpoints, $size)) {
			@content;
		}
	} @else {
		@media screen and (min-width: $size) {
			@content;
		}
	}
}
