@import "../../variables.scss";

.switch-button {
  height: 33px;
}

.switch-button__wrapper {
  margin-bottom: 0;
}

.switch-button__btn {
  background: #fff;
  width: 65px;
  height: 33px;
  border: 2px solid #fff;
  border-radius: 25px;
  padding: 2px;
  background: #000;
  transition: border-color 0.3s;
  font-size: 10px;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;

  &:after {
    content: "";
    width: 25px;
    height: 25px;
    display: block;
    background-color: #fff;
    transition: transform 0.3s, background-color 0.3s;
    border-radius: 50%;
  }

  .switch-button__label--Off {
    opacity: 1;
  }
  .switch-button__label--On {
    opacity: 0;
  }
}

.switch-button__label {
  position: absolute;
  transition: opacity 0.3s;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;

  &--On {
    left: 10px;
  }

  &--Off {
    right: 10px;
  }
}

input:checked + .switch-button__btn {
  color: map-get($colors, "yellow");
  .switch-button__label--Off {
    opacity: 0;
  }
  .switch-button__label--On {
    opacity: 1;
  }
  border-color: map-get($colors, "yellow");
  &:after {
    transform: translateX(32px);
    background-color: map-get($colors, "yellow");
  }
}
