@import "../../variables.scss";

.hero {
	&__block {
		display: flex;
		height: 88vh;
		overflow: hidden;
		position: relative;
		background-image: url("../../assets/images/crocodile__hero.jpg"),
			url("../../assets/images/crocodile__hero-lres.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
		background-position: center bottom;
		background-color: map-get($colors, "black");
		@include respond(sm) {
			height: 400px;
			background-size: 100% auto;
			background-position: center top;
		}
		@include respond(md) {
			height: 500px;
		}
		@include respond(xl) {
			height: 620px;
		}
		@include respond(xxl) {
			height: 700px;
		}
		.content__wrapper {
			align-self: flex-end;
			z-index: 1;
		}
	}
	&__background {
		position: absolute;
		width: 100%;
		height: 100%;
		background-image: url("../../assets/images/crocodile__hero-mobile.jpg"),
			url("../../assets/images/crocodile__hero-mobile-lres.jpg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center bottom;
		background-color: map-get($colors, "black");
		transform: scale(1);
		transition: transform 2000ms ease;
		@include respond(sm) {
			background-size: 100% auto;
			background-position: center top;
			background-image: url("../../assets/images/crocodile__hero-ipad.jpg");
		}
		@include respond(md) {
			background-size: 100% auto;
			background-position: center top;
			background-image: url("../../assets/images/crocodile__hero.jpg");
		}
		&:before {
			position: absolute;
			content: "";
			display: block;
			width: 100%;
			height: 50%;
			bottom: 0;
			left: 0;
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.41+99 */
			background: -moz-linear-gradient(
				top,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 0.5) 100%
			); /* FF3.6-15 */
			background: -webkit-linear-gradient(
				top,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 0.5) 100%
			); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 0.5) 100%
			); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		}
		&.zoom {
			transform: scale(1.03);
		}
	}
	&__copy {
		width: 280px;
		margin: 0 auto 20px;
		opacity: 0;

		@include respond(sm) {
			margin-bottom: 20px;
			width: 500px;
		}
		@include respond(md) {
			width: 600px;
		}

		@include respond(xl) {
			width: 700px;
		}

		svg {
			max-width:100%;
			@include respond(sm) {
				padding-top:90px;
			}
			@include respond(md) {
				padding-top:0;
			}
		}

		&.fadein {
			opacity: 1;
			transition: opacity 1000ms ease;
		}
		// &:hover {
		// 	.hero-svg {
		// 		fill: lighten(map-get($colors, "yellow"), 20%);
		// 	}
		// }
		// .hero-svg {
		// 	transition: all 500ms ease-in-out;
		// }
	}
	&__copyhide {
		visibility: hidden;
	}
}
