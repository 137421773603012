@import "../../variables.scss";

.legal__inner-container {
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
}

.legal__block {
  a, b {
    color: map-get($colors, "yellow");
  }

  p {
    font-size: 14px;
    text-align: justify;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  ul li {
    font-size: 14px;
    //list-style: none;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 53px;
  }
}
.privacy-table {

  td {
    padding-left:5px;
    border: 1px solid map-get($colors, "yellow");
  }
  border-collapse: collapse;
  th {
    color: map-get($colors, "black");
    font-weight: normal;
    padding-left:5px;
    border: 1px solid map-get($colors, "black");
    background-color: map-get($colors, "yellow");
  }
}
.legal__block-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  color: map-get($colors, "yellow");
}
