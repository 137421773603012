@import "../../variables.scss";

.cards__block {
	margin: 30px;
	.content__wrapper {
		padding-bottom: 30px;
	}
}

.card {
	background: map-get($colors, "grey");
	border: none;
	margin-bottom: 30px;
	border-radius: 0;
	&-body {
		padding: 0 37px 37px;
		@include respond(sm) {
			min-height: 120px;
		}
	}
	&-text {
		font-size: 16px;
		line-height: 21px;
	}
	&__image-wrapper {
		min-height: 120px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		img {
			height: 80px;
		}
	}
}
