@import "../../variables.scss";

.embeded-form {
  input[type="text"],
  input[type="email"],
  select {
    &,
    &:focus {
      background-color: rgba(255, 255, 255, 0.1);
      border: 1px solid #fff;
      padding: 0 30px;
      color: #fff;
      appearance: none;
    }
  }

  option {
    color: #000;
  }

  .form-row {
    position: relative;
    flex-wrap: wrap;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    label.is-hidden {
      display: none;
    }

    input.not-empty + label {
      display: none;
    }

    label {
      font-size: 16px;
      position: absolute;
      left: 30px;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.5;
      pointer-events: none;
    }

    center {
      button {
        margin-bottom: 30px;
        text-transform: uppercase;
      }
    }
  }

  .form-group {
    margin-bottom: 30px;
  }

  form {
    margin-bottom: 30px;
  }

  p {
    font-size: 14px;
    line-height: 1.7;
    text-align: center;
    font-weight: bold;
  }

  p small {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.7;
  }

  input[type="checkbox"] {
    display: none;

    & + label {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    & + label:before {
      content: "";
      flex-shrink: 0;
      flex-grow: 0;
      vertical-align: middle;
      margin-right: 20px;
      width: 22px;
      height: 22px;
      border: 1px solid #fff;
      box-shadow: inset 0px 0px 0px 4px #000;
      display: inline-block;
    }

    &:checked + label:before {
      background: #fff;
    }
  }

  .container2 {
    padding-left: 50px;
    margin: 30px 0px;
    display: flex;

    small {
      font-weight: bold;
      line-height: 1.5;
      font-size: 14px;
    }
  }
}

.required-copy {
  color: #fff;
  opacity: 0.5;
  margin-bottom: 10px;
  font-size: 16px;
}

#Inputs {
  @include respond(md) {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0px;
  }

  br {
    display: none;
  }

  .form-row {
    @include respond(md) {
      width: 100%;
      margin: 10px auto;
    }

    :nth-child(1) {
      padding-right: 15px;
    }

    :nth-child(2) {
      padding-left: 15px;
    }

    &:nth-child(1) {
      order: 3;
    }

    &:nth-child(4) {
      order: 4;
    }
  }
}

.row--captcha {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: block;

  .col-md-1 {
    display: none;
  }

  .col-md-4,
  .captcha-column {
    padding: 0;
    max-width: 100%;

    @include respond(md) {
      max-width: (100% / 3);
    }
  }

  #InputCaptcha {
    width: 50%;
  }
}

#captchaImg {
  @include respond(md) {
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .embeded-form {
    .form-row {
      display: block;
      margin: 0px auto;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .col {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      center {
        button {
          width: 100%;
        }
      }
    }

    .container2 {
      margin: 30px 0px;

      small {
        width: 300px;
      }

      .checkmark {
        top: -30%;
      }
    }

    #Inputs {
      flex-direction: column;
      margin: 30px -15px;

      @include respond(md) {
        display: flex;
        flex-wrap: wrap;
        margin: 30px -15px 30px -15px;
      }

      .form-row {
        :nth-child(1),
        :nth-child(2) {
          padding: 0px 5px;
        }

        .col > * {
          padding-left: 20px;
        }
      }
    }

    .row--captcha {
      display: block;
    }

    #InputCaptcha {
      width: 100%;
    }
  }
}
