@import "./variables.scss";

@font-face {
	font-family: "RobotoReg";
	src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "RobotoMed";
	src: url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "RobotoBold";
	src: url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "RobotoItalic";
	src: url("./assets/fonts/Roboto-Italic.ttf") format("truetype");
}
@font-face {
	font-family: "RobotoBoldItalic";
	src: url("./assets/fonts/Roboto-BoldItalic.ttf") format("truetype");
}

body {
	margin: 0;
	background: map-get($colors, "black");
	color: map-get($colors, "white");
	font-family: "RobotoReg", Arial;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	min-height: 100vh;

	@include respond(md) {
		font-size: 18px;
	}

	h1 {
		font-family: "RobotoBold", Arial;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeLegibility;
		color: map-get($colors, "yellow");
		font-size: 28px;
		@include respond(sm) {
			font-size: 35px;
		}
	}
	h2 {
		font-family: "RobotoMed", Arial;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeLegibility;
		color: map-get($colors, "orange");
		font-size: 22px;
		line-height: 28px;
		font-weight: 600;
		@include respond(md) {
			font-size: 24px;
		}
	}
	h3 {
		font-family: "RobotoMed", Arial;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeLegibility;
		color: map-get($colors, "yellow");
		font-size: 20px;
	}
	p a {
		font-family: "RobotoReg", Arial;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeLegibility;
		color: map-get($colors, "orange");
		&:hover {
			color: map-get($colors, "orange");
			text-decoration: underline;
		}
	}
	p {
		line-height: 26px;
		font-size: 16px;
		@include respond(md) {
			line-height: 31px;
			font-size: 18px;
		}
	}
	p.acronym {
		text-align: left;
		font-size:14px;
		line-height: 18px;
	}
	p.highlight {
		font-size: 20px;
		font-family: "RobotoBold", Arial;
		@include respond(md) {
			font-size: 24px;
		}
		i {
			font-family: "RobotoBoldItalic", Arial;
		}
	}
	button.btn {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeLegibility;
		font-family: "RobotoReg", Arial;
		font-weight: 700;
		font-size: 14px;
	}
	.content {
		img {
			width: 100%;
			padding: 20px 0;
		}
	}
	ul li {
		font-size: 12px;
		word-wrap: break-word;
	}
}

.content__wrapper {
	max-width: 945px;
	margin: 0 auto 30px;
	text-align: center;
}
.content__img {
	img {
		width: 100%;
		margin-bottom: 20px;
	}
}
.image__wrapper {
	overflow: auto;
	width: 100%;
	margin: 20px auto 10px;
	@include respond(sm) {
		margin: 30px auto 10px;
	}
	img {
		width: 750px;
		@include respond(sm) {
			width: 100%;
		}
	}
}

.note {
	font-size: 14px;
	text-align: center;
	line-height: 16px;
	&.alignleft {
		text-align:left;
	}
}

.btn-primary {
	background: map-get($colors, "yellow");
	color: map-get($colors, "black");
	border: none;
	border-radius: 0;
	//text-transform: uppercase;
	position: relative;
	width: 290px;
	padding: 0 20px;
	text-align: center;
	z-index: 1;
	display: flex;
	display: -ms-flexbox;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	-ms-flex-pack: center;
	letter-spacing:-0.05em;
	margin: 20px auto 0;
	min-height: 43px;
	line-height: 20px;
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  		height:42px;
   }
	@include respond(sm) {
		letter-spacing:0em;
		width: 360px;
	}
	&:hover {
		color: map-get($colors, "black");
		background: map-get($colors, "yellow");
		&:after {
			transform-origin: center bottom;
			transform: scaleY(1);
		}
	}
	&:before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		position: absolute;
		left: 0;
		top: 0;
		border-right: 18px solid transparent;
		border-top: 12px solid map-get($colors, "black");
	}
	&:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: block;
		z-index: -1;
		background: map-get($colors, "orange");
		transform-origin: center top;
		transform: scaleY(0);
		transition: transform 0.25s ease-in-out;
	}
	span {
		display: block;
		width: 0;
		height: 0;
		position: absolute;
		right: 10px;
		top: 50%;
		margin-top: -6px;
		border-top: 6px solid transparent;
		border-bottom: 6px solid transparent;
		border-left: 8px solid map-get($colors, "black");
	}
}

.testing__wrapper, .button__wrapper {
	display:flex;
	justify-content: center;
	flex-direction:column;
	a{
		display:flex;
		justify-content: center;
		margin: 0 auto;
		max-width:360px;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			width:359px;
		}
		.btn-primary {
			margin: 20px 0;
		}
	}
}

a:hover {
	text-decoration: none;
}

// .btn-primary.more__copy {
// 	padding-top: 6px;
// 	padding-bottom: 6px;
// 	line-height: 16px;
// 	white-space: normal;
// }

hr {
	background-image: url("./assets/images/line_mobile.png");
	background-repeat: no-repeat;
	background-size: 100% auto;
	height: 2px;
	margin: 20px 0;
	@include respond(sm) {
		margin: 30px 0;
		background-image: url("./assets/images/line_desktop.png");
	}
}

.testing__highlight {
	text-align: center;
	margin: 20px 0;
	@include respond(sm) {
		margin: 50px 0;
	}
	hr {
		margin: 0;
	}
	p {
		margin: 10px 0;
		font-size: 24px;
		@include respond(sm) {
			font-size: 24px;
		}
	}
	color: map-get($colors, "yellow");
	font-weight: bold;
}
.cards__block .padding-bottom {
	padding-bottom: 20px;
}
.padding-bottom {
	padding-bottom: 10px;
	@include respond(sm) {
		padding-bottom: 30px;
	}
}

.highlight,
h1,
h2,
h3,
p {
	sup {
		font-size: 12px;
		margin-left: 1px;
		vertical-align: 2px;
		@include respond(md) {
			vertical-align: 5px;
		}
	}
}
.bold {
	font-family: "RobotoBold";
}

h1 sup {
	vertical-align: 5px;
	@include respond(md) {
		vertical-align: 10px;
	}
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

.frame {
	overflow-y: auto;
	border: 1px solid black;
	height: 3em;
	width: 10em;
	line-height: 1em;
}

.desktop-only {
	display:none;
	@include respond(lg) {
		display:block;
	}
}
.md-up {
	display:none;
	@include respond(md) {
		display:block;
	}
}

.nowrap {
	white-space:nowrap;
}

h1 i {
	margin-right: 5px;
}
h2 i {
	margin-right: 4px;
}

/*Scrollbar style*/
.image__wrapper::-webkit-scrollbar {
	-webkit-appearance: none;
}
.image__wrapper::-webkit-scrollbar:vertical {
	width: 11px;
}
.image__wrapper::-webkit-scrollbar:horizontal {
	height: 8px;
	background-color: map-get($colors, "lightgrey");
}
.image__wrapper::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: map-get($colors, "orange");
}
