@import "../../variables.scss";

.cookie-preferences {
  min-height: calc(100vh - 338px);
  @include respond(md) {
    min-height: calc(100vh - 267px);
  }
}

.cookie-preferences__type-title-area {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  padding-bottom: 10px;
}

.cookie-preferences__type-title {
  max-width: 80%;
  color: #fff;
  margin-bottom: 0;
  font-size: 21px;
}

.cookie-preferences__type-copy {
  @include respond(md) {
    max-width: 80%;
  }
}

.cookie-preferences__type-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  @include respond(md) {
    position: absolute;
    top: 100%;
    right: 0;
  }
}

.cookie-preferences__types {
  width: 920px;
  max-width: 100%;
  margin: 0 auto;
}

.cookie-preferences__type-block {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}
