@import "../../variables.scss";

.secondaryhero {
	&__block {
		&-bg {
			height: 200px;
			background-image: url("../../assets/images/hero__secondary.jpg");
			background-repeat: no-repeat;
			background-size: cover;
			height: 300px;
			flex-direction: column;
			@include respond(md) {
				padding: 30px 100px;
			}
			@include respond(xxl) {
				background-size: cover;
				background-position-y: -100px;
			}
		}
	}

	&__header {
		margin-top: -200px;
		@include respond(md) {
			margin-top: -150px;
		}
		.content__wrapper {
			padding: 30px;
			@include respond(md) {
				padding: 30px 100px 20px;
			}
		}
		h1 {
			font-size: 24px;
			@include respond(md) {
				font-size: 35px;
			}
		}
	}
}
